<template>
    <div>
        <h3>Punktspiele</h3>
        <p>Termine für Punktspiele werden automatisch nach dem angegebenen Datum ausgeblendet, sie müssen also nicht explizit gelöscht werden.</p>    
        <form id="punktspielForm" @submit.prevent="submitForm">
            <label>Altersklasse: </label>
            <br/>
            <select class='mobileAdjusted' name="akSelect" id="akSelect" v-model='ak' @change="loadMatches()" required>
                <option v-for="akEntry in possibleAks" :key="akEntry.id" :value="akEntry.id">
                    {{akEntry.name}}
                </option>
            </select> 
            <br/>
            <br/>
            <label>Datum:</label>
            <br/>
            <input class='mobileAdjusted' type="date" v-model="dateMatch" required/>
            <br/>
            <br/>
            <label>Uhrzeit:</label>
            <br/>
            <input class='mobileAdjusted' type="time" v-model="timeMatch" required/>
            <br/>
            <br/>
            <label>Ort:</label>
            <br/>
            <input class='mobileAdjusted' type="text" size="50" v-model.trim="placeMatch" required/>
            <br/>
            <br/>
            <label>Gegner:</label>
            <br/>
            <input class='mobileAdjusted' type="text" size="50" v-model.trim="opponent" required/>
        </form>  
        <div class="warning" v-if="matchError">{{matchError}}</div>
        <div class="topspace fitContent">   
            <button class="accountButton" @click="saveMatch()">Speichern</button>  
            <button class="accountButton" @click="clearMatch()">Abbrechen</button>
        </div> 
        <matches-table v-if="ak !== null" editable :matches="matches" :title="'Gespeicherte Punktspieltermine ' + akName" @editmatch="editMatch"/>
    </div>
</template>

<script>
import MatchesTable from './base/MatchesTable.vue'

export default {
    
     name:"matchesTab",
    components:{
        MatchesTable
    },
    data(){
        return{
            ak: null,
            matchId: null,
            dateMatch: null,
            timeMatch: null,
            placeMatch: null,
            opponent: null,
            matches: [],
            possibleAks: [],
            matchError: null
        }
    },
    created(){
        this.loadAks()
    },
    computed:{
        akName(){
           const matchingEntries = this.possibleAks.filter(ak => ak.id === this.ak)
            if(matchingEntries === null || matchingEntries.size < 1){
                return ""
            }else{
                return matchingEntries[0].name
            }
        }
    },
    methods:{
        loadAks(){
            this.axios.get('api/aks')
            .then(result =>{
                this.possibleAks = result.data.records
            })
        },
        loadMatches(){
            this.axios.get(`/api/matches/${this.ak}`)
                .then(response => {
                    this.matches = response.data.records
                })
            this.clearMatch()    
        },
        saveMatch(){
            this.matchError = null
            this.matchError = this.validateMatchInput();
            if(this.matchError !== null){
                return
            }
            const token = this.$store.getters.token
            if(this.matchId === null){
                this.axios({
                        method: 'post',
                        url: '/api/interna/match',
                        data: {
                            date: this.dateMatch,
                            time: this.timeMatch,
                            place: this.placeMatch,
                            ak: this.ak,
                            opponent: this.opponent
                        },
                        headers: {'Content-Type': 'application/json;charset=UTF-8',
                                'Authorization': `token ${token}`}         
                    })
                    .then(_ => {
                        this.clearMatch()
                        this.loadMatches()
                    })
            }else{
                 this.axios({
                        method: 'put',
                        url: '/api/interna/updateMatch',
                        data: {
                            id: this.matchId,
                            date: this.dateMatch,
                            time: this.timeMatch,
                            place: this.placeMatch,
                            ak: this.ak,
                            opponent: this.opponent
                        },
                        headers: {'Content-Type': 'application/json;charset=UTF-8',
                                'Authorization': `token ${token}`}         
                    })
                    .then(_ => {
                        this.clearMatch()
                        this.loadMatches()
                    })
            }
        },
        validateMatchInput(){
            let result = ''
            if(this.dateMatch === null){
                result += "Das Datum wurde nicht angegeben.\n"
            }
            if(this.timeMatch === null){
                result += "Die Uhrzeit wurde nicht angegeben.\n"
            }
            if(this.placeMatch === null || this.placeMatch.length === 0){
                result += "Der Ort wurde nicht angegeben.\n"
            }
            if(this.placeMatch !== null && this.placeMatch.length > 50){
                result += "Der Ortsname ist zu lang (bis zu 50 Zeichen erlaubt).\n"
            }
            if(this.opponent === null || this.opponent.length === 0){
                result += "Der Gegener wurde nicht angegeben.\n"
            }
            if(this.opponent !== null && this.opponent.length > 50){
                result += "Der Name des Gegners ist zu lang (bis zu 50 Zeichen erlaubt).\n"
            }
            if(this.ak === null){
                result += "Die Alterklasse wurde nicht angegeben."
            }
            return result.length === 0 ? null : result
        },
        clearMatch(){
            this.dateMatch = null
            this.timeMatch = null
            this.placeMatch = null
            this.opponent = null
            this.matchId = null
            this.matchError = null
        },
        editMatch(index){
            const selectedMatch = this.matches[index]
            const dateParts = selectedMatch.date.split('.')
            const dateToShow = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
            this.matchId = selectedMatch.key
            this.dateMatch = dateToShow
            this.timeMatch = selectedMatch.time
            this.placeMatch = selectedMatch.place
            this.opponent = selectedMatch.opponent 
        }
    }
}
</script>