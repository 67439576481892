<template>
<div id="menu-side" :class="[openMenu ? 'menu-side-open' : 'menu-side-closed']">
     <div class="responsive-menu">
        <div class="background"></div>
        <div class="impDiv">
            <button class="smallMenuButton" @click="hideAndChange('/impressum')">Impressum und Datenschutz</button>
        </div>    
        <div class="menuDiv">
            <ul class="menu">
                <li class="menu"><button class="menuButton" @click="hideAndChange('/')">Home</button></li>
                <!-- <li class="menu"><router-link class="menuButton" onClick="hideMenu(); return true;" to="/reports">Berichte</router-link></li> -->
                <li class="menu"><button class="menuButton" @click="hideAndChange('/training')">Training</button></li>
                <li class="menu"><button class="menuButton" @click="hideAndChange('/teams')"> Mannschaften</button></li>
                <li class="menu"><button class="menuButton" @click="hideAndChange('/events')">Termine</button></li>
                <li class="menu"><button class="menuButton" @click="hideAndChange('/links')">Links</button></li>
                <li class="menu"><button class="menuButton" @click="hideAndChange('/login')">Interna</button></li>
                <li class="menu"><button class="menuButton" @click="hideAndChange('/contact')">Kontakt</button></li>
            </ul>
        </div>

    </div>
</div>    
</template>

<script>
export default {
    name:"MainMenu",
    props:{
        openMenu:{
            type: Boolean,
            default: false
        }
    },
    methods:{
        hideAndChange(route) {
             this.$emit('hideMenu')
             this.$router.push(route)
        }
    }
}
</script>