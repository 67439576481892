<template>
    <div>
        <router-link class="linkButton" to="/interna/home"> <h2>Badminton - Interna</h2></router-link>
        <div class="nav">
            <ul class='nav'>
                <li class='nav'><router-link class='nav' to="/interna/galerie">Galerie</router-link></li>
                <li class='nav'><router-link class='nav' to="/interna/newseditor">Newseditor</router-link></li>
                <li class='nav'><router-link class='nav' to="/interna/termineditor">Terminverwaltung</router-link></li>
                <li class='nav'><router-link class='nav' to="/interna/material">Material</router-link></li>
                <li class='nav'><button class='nav' @click="logout()">Logout</button></li>
            </ul>
        </div>
        <router-view></router-view>
    </div>    
</template>

<script>
export default {
    name:"Interna",
    methods:{
        logout(){          
            const token = this.$store.getters.token
            this.axios({
                    method: 'post',
                    url: '/api/interna/logout',
                    headers: {'Authorization': `token ${token}`}         
                }).then(_ =>{   
                 this.$store.dispatch('logout')
                 this.$router.replace('/login')
            })
           
        }
    }
}
</script>