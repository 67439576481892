import {createStore } from 'vuex'
const axios = require("axios");

let timer;
const store = createStore({
    state(){
        return{
            userId: null,
            username: null,
            token: null
        };
    },
    mutations:{
        setUser(state, payload) {
            state.token = payload.token;
            state.userId = payload.userId;
            state.username = payload.username;
          },
          updateUsername(state,payload){
            state.username = payload.username;
          }
    },
    getters:{
        userId(state) {
            return state.userId;
        },
        token(state) {
            return state.token;
        },
        isAuthenticated(state) {
            return !!state.token;
        },
        username(state){
            return state.username;
        }
    },
    actions:{
        async login(context, payload){
          return  axios({
                    method: 'post',
                    url: '/api/login',
                    data: {
                        user: payload.userName,
                        password: payload.pswd,
                    },
                    headers: {'Content-Type': 'application/json;charset=UTF-8'}       
                })
                .then(response => {
                    context.commit('setUser', {
                        token: response.data.token,
                        userId: response.data.id,
                        username: payload.userName
                      });

                    const expiresIn = +response.data.expiresIn;
                    //const expiresIn = 30000;
                    const expirationDate = new Date().getTime() + expiresIn;

                    sessionStorage.setItem('token', response.data.token);
                    sessionStorage.setItem('userId', response.data.id);
                    sessionStorage.setItem('username', payload.userName);
                    sessionStorage.setItem('tokenExpiration', expirationDate);

                    timer = setTimeout(function() {
                        context.dispatch('autoLogout');
                    }, expiresIn);
                })
                .catch( error => {
                    throw error;
                })
        },
        logout(context) {
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('username');
            sessionStorage.removeItem('tokenExpiration');
        
            clearTimeout(timer);
        
            context.commit('setUser', {
              token: null,
              userId: null,
              username: null
            });
 
          },
          //versuchen mithilfe der im Browser gespeicherten Daten anzumelden
          //falls diese noch gültig sind
          tryLogin(context) {
            const token = sessionStorage.getItem('token');
            const userId = sessionStorage.getItem('userId');
            const username = sessionStorage.getItem('username')
            const tokenExpiration = sessionStorage.getItem('tokenExpiration');
        
            const expiresIn = +tokenExpiration - new Date().getTime();
        
            if (expiresIn < 0) {
              return;
            }
        
            timer = setTimeout(function() {
              context.dispatch('autoLogout');
            }, expiresIn);
        
            if (token && userId) {
              context.commit('setUser', {
                token: token,
                userId: userId,
                username: username
              });
            }
          },
          autoLogout(context) {
            context.dispatch('logout');
          },
          updateUsername(context, payload){
            sessionStorage.setItem('username', payload.userName);
            context.commit('updateUsername', payload);
          }
    }
});

export default store;