<template>
    <article>
        <br/>
        <h3>Impressum</h3>
        <p>Verantwortlicher für den Inhalt ist gemäß § 55 Abs. 2 Rundfunkstaatsvertrag (RStV):</p>
        <p>Julia Krone<br />
            <b>Kontakt:</b>
            E-Mail: <router-link class="mailButton" to="/contact"> kontakt_jugend@badminton-holtenau.de </router-link>
        </p>
        <p><b>Ausschluss der Haftung:</b></p>
        <p>1. Haftung für Inhalte<br />
            Die Inhalte dieser Homepage wurden mit größter Sorgfalt erstellt.
            Wir übernehmen jedoch keine Gewähr dafür, dass alle Inhalte vollständig, richtig und aktuell sind. <br />
            Sollten wir mit dem Inhalt dieser Seite konkrete Rechte Dritter verletzten, werden diese Inhalte selbstverständlich unverzüglich entfernt,
            sobald wir über die Verletzung in Kenntnis gesetzt wurden.
        </p>
        <p>2. Haftung für Links<br />
            Auf dieser Homepage befinden sich Links, die auf externe Internetseiten Dritter verweisen, auf deren Inhalte wir jedoch keinen Einfluss haben.
            Deshalb übernehmen wir keine Verantwortung für die jeweiligen Inhalte.<br />
            Die Verantwortung verbleibt beim jeweiligen Anbieter/Betreiber.
        </p>
        <p>3. Urheberrecht<br />
            Die Inhalte dieser Homepage sind, soweit möglich, urheberrechtlich geschützt.
            Die Vervielfältigung, Bearbeitung, Verbreitung oder Nutzung ist nur mit schriftlicher Genehmigung erlaubt und
            erfordert insbesondere eine ausdrückliche Erwähnung des Urhebers.
        </p>
        <br/>
    <h3>Datenschutzerklärung</h3>
    <p>Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz "Daten") 
        innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen
        (nachfolgend gemeinsam bezeichnet als "Onlineangebot"). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. "Verarbeitung"
        oder "Verantwortlicher" verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).<br/>
    </p>
    <p><b>Verantwortlicher</b></p>
    <p>Krone, Julia<br/>
        <router-link class="mailButton" to="/contact"> kontakt_jugend@badminton-holtenau.de </router-link>
    </p>
    <p><b>Arten der verarbeiteten Daten:</b></p>
    <p>
        <ul>
            <li> Bestandsdaten (z.B., Namen, Adressen).</li>
            <li> Kontaktdaten (z.B., E-Mail).</li>
            <li> Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).</li>
            <li> Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</li>
            <li> Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).</li>
        </ul>
    </p>
    <p><b>Kategorien betroffener Personen</b></p>
    <p>Besucher und Nutzer des Onlineangebotes (nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als "Nutzer").
    </p>
    <p><b>Zweck der Verarbeitung</b></p>
    <p>
        <ul>
            <li> Zurverfügungstellung des Onlineangebotes, seiner Funktionen und  Inhalte.</li>
            <li> Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.</li>
            <li> Sicherheitsmaßnahmen.</li>
            <li> Reichweitenmessung/Marketing </li>
        </ul> 
    </p>
    <p><b>Verwendete Begrifflichkeiten </b></p>
    <p>"Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person 
        (im Folgenden "betroffene Person") beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, 
        insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) 
        oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, 
        wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.</p>
    <p>"Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit 
        personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten.</p>
    <p>Als "Verantwortlicher" wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen 
        über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.</p>
    <p><b>Maßgebliche Rechtsgrundlagen</b></p>
    <p>Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. 
        Sofern die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die Rechtsgrundlage für die Einholung von Einwilligungen 
        ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher 
        Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen 
        Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist 
        Art. 6 Abs. 1 lit. f DSGVO. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine 
        Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.</p>     
    <p><b>Zusammenarbeit mit Auftragsverarbeitern und Dritten</b></p>
    <p>Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen (Auftragsverarbeitern oder Dritten) offenbaren, 
        sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis 
        (z.B. wenn eine übermittlung der Daten an Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung erforderlich ist), 
        Sie eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten Interessen 
        (z.B. beim Einsatz von Beauftragten, Webhostern, etc.). </p>
    <p>Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog. "Auftragsverarbeitungsvertrages" beauftragen, 
        geschieht dies auf Grundlage des Art. 28 DSGVO.</p>
    <p><b>Übermittlungen in Drittländer</b></p>
    <p>Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen Wirtschaftsraums (EWR)) verarbeiten 
        oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder Offenlegung, 
        bzw. übermittlung von Daten an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten, 
        auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht. 
        Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in einem Drittland nur beim Vorliegen der 
        besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, 
        wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das "Privacy Shield") 
        oder Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen (so genannte "Standardvertragsklauseln").</p>
    <p><b>Rechte der betroffenen Personen</b></p>
    <p>Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten 
        sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15 DSGVO.</p>
    <p>Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden 
        unrichtigen Daten zu verlangen.</p>
    <p>Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe 
        des Art. 18 DSGVO eine Einschränkung der Verarbeitung der Daten zu verlangen.</p>
    <p>Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu erhalten 
        und deren übermittlung an andere Verantwortliche zu fordern. </p>
    <p>Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.</p>
    <p><b>Widerrufsrecht</b></p>
    <p>Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen.</p>
    <p><b>Widerspruchsrecht</b></p>
    <p>Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. 
    Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.</p>
    <p><b>Löschung von Daten</b></p>
    <p>Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt.
        Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die bei uns gespeicherten Daten gelöscht, 
        sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. 
        Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. 
        D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen 
        aufbewahrt werden müssen.</p>
    <p>Nach gesetzlichen Vorgaben in Deutschland erfolgt die Aufbewahrung insbesondere für 6 Jahre gemäß § 257 Abs. 1 HGB 
        (Handelsbücher, Inventare, Eröffnungsbilanzen, Jahresabschlüsse, Handelsbriefe, Buchungsbelege, etc.) sowie für 10 Jahre gemäß § 147 Abs. 1 AO 
        (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege, Handels- und Geschäftsbriefe, Für Besteuerung relevante Unterlagen, etc.). </p>
    <p><b>Hosting</b></p>
    <p>Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen,
        Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs dieses 
        Onlineangebotes einsetzen. </p>
    <p>Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten
        von Kunden, Interessenten und Besuchern dieses Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren 
        Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).</p>
    <p><b>Erhebung von Zugriffsdaten und Logfiles</b></p>
    <p>Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten über 
        jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen Webseite, 
        Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, 
        Referrer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider.</p>
    <p>Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 7 Tagen 
        gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen 
        Vorfalls von der Löschung ausgenommen.</p>
    <p><b>Kontaktaufnahme</b></p>
    <p>Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung 
        gem. Art. 6 Abs. 1 lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können in einem Customer-Relationship-Management System ("CRM System") oder vergleichbarer 
        Anfragenorganisation gespeichert werden.</p>
    <p>Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen 
        Archivierungspflichten.</p> 
    <br/>    
    <p>Erstellt mit <a href="https://datenschutz-generator.de" class="mailButton" rel="nofollow" target='_blank'>Datenschutz-Generator.de von RA Dr. Thomas Schwenke</a> 
        und vom Websiteinhaber angepasst. </p>
</article>
</template>

<script>
export default {
    name:"Impressum"
}
</script>