<template>
    <div>
        <h3>{{title}}</h3>
        <table class="eventstab mobile">
            <thead class='mobile'>
                <tr class='mobile'>
                    <th class="termine mobile">Was</th>
                    <th class="termine mobile">Wann</th>
                    <th class="termine mobile">Wo</th>
                    <th :class="[editable? 'termine' : 'terminelast' , 'mobile']">Weiteres</th>
                    <th class="terminelast mobile" v-if="editable"></th>
                </tr>
            </thead>  
            <tbody class='mobile'>
                <tr class='mobile' v-for="(x, index) in events" :key=x.id>
                    <td class="termine mobile">{{x.eventname}}</td>
                    <td class="termine mobile">{{x.date}}</td>
                    <td class="termine mobile">{{x.place}}</td>
                    <td :class="[editable? 'termine' : 'terminelast' , 'mobile']">
                        <a target="_blank" v-if="x.others" :href="x.others">Ausschreibung</a>
                        <span v-else>---</span>
                    </td>
                    <td class="terminelast mobile" v-if="editable"><button class="accountButton" @click="editEvent(index)">Bearbeiten</button></td>
                </tr>
            </tbody>
        </table>
    </div>    
</template>

<script>
export default {
    name:"eventsTable",
    props:{
        title:{
            type:String
        },
        events:{
            type:Array,
            default: () => []
        },
        editable:{
            type: Boolean,
            default: false
        }
    },
    methods:{
        editEvent(index){
            this.$emit('editevent', index)
        }
    }
}
</script>