<template>
    <div>
        <h3> Unsere Trainingszeiten</h3>
        <p> Das Training für Kinder und Jugendliche findet</p>
        <table class='zeiten' align='center'>
            <tr class='zeiten'>
                <td class='zeiten'> <b> montags </b></td>
                <td class='zeiten'> <b>17 - 19 Uhr </b> </td>
            </tr>
        </table>
        <p>in der Halle im <b>Groenhoffweg</b> in Kiel-Holtenau statt.</p>
        <p> Willkommen ist jeder <b>ab 7 Jahren</b>, egal ob Anfänger(in) oder Fortgeschrittene(r).</p>
        <div class ='sideBySide hideForMobile'><img class='hideForMobile' src='../assets/img/Coaching20171007.jpg' alt='Taktik' height='200' width='270'/>
                                <img class='hideForMobile' src='../assets/img/Sara3.jpg' alt='Benyamin' height='200' width='150'/>
                                <img class='hideForMobile' src='../assets/img/Domenique220171007.jpg' alt='Sara' height='200' width='150'/></div>
        <div class ='sideBySide'><img src='../assets/img/GruppeGemischt.jpg' alt='SGruppe' height='200' width='270'/>
                                <img src='../assets/img/CasparTheo.jpg' alt='U13' height='200' width='150'/>
                                <img src='../assets/img/SaraJulia220171007.jpg' alt='Training' height='200' width='220'/></div>
        <div>
            <article>
                <p> Bei unserem Training geht es neben dem Erlernen von Technik und Taktik auch um den Spaß am gemeinsamen Sport.
                    Dabei ist auch die Schulung von koordinativen Fähigkeiten, Schnelligkeit, Ausdauer und Kraft/Stabilität ein fester Bestandteil unserer Trainingsstunden.</p>
                    <p/>
                <p> Die Teilnahme an Turnieren wird jedem ermöglicht und verstärkt gefördert aber nicht erzwungen.
                    Wichtig ist uns eine Trainingsatmosphäre, die einen gesunden sportlichen Ehrgeiz fördert. 
                    Natürlich legen wir viel Wert auf sportliche Fairness und fördern mit der Teilnahme an Mannschaftswettkämpfen 
                    und anderen gemeinsamen Aktivitäten den Gruppenzusammenhalt.</p>
                <p> Auch ermutigen wir die  Kinder und Jugendlichen zur Mitgestaltung einzelner Aspekte der Übungsstunden. </p>
                <p/>
                <p> Wer nun Lust bekommen hat, kann gerne beim Training vorbeischauen. Am besten meldet ihr euch vorher kurz unter 0170-5356339.
                    Schläger für die ersten Trainingsstunden sind vorhanden.
                </p>
            </article>
        </div>
        <h3> Unsere Jugendtrainer </h3>
        <div class ='sideBySide'>
            <p> 
                <img  src='../assets/img/LucaTrainer.jpg' alt='luca' height='220' width='160'/>
            </p>
            Luca
        </div>
    </div>    
</template>

<script>
export default {
    name:"Training"
}
</script>