<template>
    <div>       
        <div class="gallery" v-if="!showDetail">
            <div class="galleryLink"><router-link class="newsButton" to="/interna/galerie">Zurück zur Übersicht</router-link></div>
            <h4>{{folderNameToShow}}</h4>
            <div >
                <div class="imageContainer" v-for="(pic, index) in pics" :key="pic.id">
                    <button class="gallery" @click="goToPicDetail(index)" >
                        <img class="gallerypic" :src="getImgUrl(pic.path)" :height='pic.height' :width='pic.width'/>
                    </button>
                </div>
            </div>
            <div class="galleryLink"><router-link class="newsButton" to="/interna/galerie">Zurück zur Übersicht</router-link></div>
        </div>
        <div v-else>
            <h4>{{folderNameToShow}}</h4>
            <button v-if="currentIndex>0" class="picButtonLeft" @click="prevPic()"><img class="picsFolderRight" src='../assets/img/ball_gedreht_left.png' height='40' width='50'/></button>    
                <img class="galleryBigPic"
                    :src="getImgUrl(currentPicture.path)"/> 
            <button v-if="currentIndex<pics.length-1" class="picButtonRight" @click="nextPic()"><img class="picFolder" src='../assets/img/Ball_gedreht.png' height='40' width='50'/></button>
            <div>                
                <button class="newsButton" @click="returnToFolder()">Zurück zum Ordner</button>               
            </div>
        </div>
    </div>   
</template>

<script>
export default {
    name:"PictureView",
    props:{
        folderName : String
    },
    data(){
        return{
            pics:{
                type: Array,
                default: () => []
            },
            showDetail:{
                type: Boolean,
                default: false
            },
            currentPicture:{
                type: Object,
                default: null
            },
            currentIndex:{
                type: Number,
                default: -1
            }
        }
    },
    created(){
        this.loadPics()
    },
    computed:{
        folderNameToShow(){
            if(this.folderName == 'others'){
                return 'ältere Trainingsfotos'
            }else if(this.folderName == 'Punktspiel140921'){
                return 'U15-Punktspiel, 21.09.2014'
            }else{
                return this.folderName
            }
        },
        filenamePrefix(){
            let prefix = ''
            switch(this.folderName){
                case 'others': {
                    prefix = 'O_';
                    break;
                }
                case 'Punktspiel140921': {
                    prefix = 'U15_';
                    break;
                }
                case 'U17Mannschaft': {
                    prefix = 'U17_';
                    break;
                }
                case 'Weihnachtsfeier15': {
                    prefix = 'WF_';
                    break;
                }
                default : prefix = ''
            }
            return prefix
        }
    },
    methods:{
        loadPics(){
            const token = this.$store.getters.token
            this.axios.get(`/api/interna/pictures/${this.filenamePrefix}`,
            {headers: 
                {
                 'Authorization': `token ${token}`
                }
            })
            .then(response => {
                this.pics = response.data.records              
            })
            this.showDetail = false
        },
        getImgUrl(imageurl){           
            if(imageurl){
                return require(`../assets/img/${this.folderName}/${imageurl}`);
            }else{
                return null
            }
        },
        goToPicDetail(index){
            this.currentPicture = this.pics[index]
            this.currentIndex = index
            this.showDetail = true
        },
        returnToFolder(){
            this.currentPicture = null
            this.currentIndex = -1
            this.showDetail = false
        },
        prevPic(){
            if(this.currentIndex > 0){
                this.currentIndex--
                this.currentPicture = this.pics[this.currentIndex]
            }
        },
        nextPic(){
            if(this.currentIndex < this.pics.length-1){
                this.currentIndex++
                this.currentPicture = this.pics[this.currentIndex]
            }
        }
    }
}
</script>