<template>
    <div>
        <h3>Allgemeine Termine</h3>
        <p>Für Allgemeine Termine kann der Zeitpunkt flexibler eingegeben werden (es ist daher nur ein Textfeld), es <b>muss</b> allerdings ein "gültig bis"-Datum angegeben werden,
            nachdem der Termin dann auch automatisch ausgeblendet wird.</p>
        <div>     
            <form id="terminForm" @submit.prevent="submitForm">
                <label>Was:</label>
                <br/>
                <input class='mobileAdjusted' type="text" size="50" v-model.trim="eventName" required/>
                <br/>
                <br/>
                <label>Wann:</label>
                <br/>
                <input class='mobileAdjusted' type="text" size="50" v-model.trim="eventDateTime" required/>
                <br/>
                <br/>
                <label>Wo:</label>
                <br/>
                <input class='mobileAdjusted' type="text" size="50" v-model.trim="eventPlace" required/>
                <br/>
                <br/>
                <label>Link zur Ausschreibung (optional):</label>
                <br/>
                <input class='mobileAdjusted' type="text" size="50" v-model.trim="eventOthers"/>
                <br/>
                <br/>
                <label>Gültig bis:</label>
                <br/>
                <input class='mobileAdjusted' type="date" v-model="eventExpires" required/>
            </form>  
            <div class="warning"  v-if="eventError">{{eventError}}</div>
            <div class="topspace fitContent">   
                <button class="accountButton" @click="saveEvent()">Speichern</button>  
                <button class="accountButton" @click="clearEvent()">Abbrechen</button>
            </div>
        </div> 
        <div>  
            <events-table title="Allgemeine Termine" :events="events" editable @editevent="editEvent"/>   
        </div> 
    </div>    
</template>

<script>
import EventsTable from './base/EventsTable.vue'

export default {
    name:"eventsTab",
    components:{
        EventsTable
    },
    data(){
        return{
            events: [],
            eventId: null,
            eventName: null,
            eventDateTime: null,
            eventPlace: null,
            eventOthers: null,
            eventExpires: null,
            eventError: null
        }
    },
    created(){
        this.loadEvents()
    },
    methods:{
        loadEvents(){
            this.axios.get('/api/events')
                .then(response => {
                    this.events = response.data.records
                }) 
        },
 
        saveEvent(){
            this.eventError = null
            this.eventError = this.validateEventInput();
            if(this.eventError !== null){
                return
            }
            const token = this.$store.getters.token
            if(this.eventId === null){
                this.axios({
                        method: 'post',
                        url: '/api/interna/event',
                        data: {
                            eventname: this.eventName,
                            time: this.eventDateTime,
                            place: this.eventPlace,
                            expires: this.eventExpires,
                            others: this.eventOthers
                        },
                        headers: {'Content-Type': 'application/json;charset=UTF-8',
                                'Authorization': `token ${token}`}         
                    })
                    .then(_ => {
                        this.clearEvent()
                        this.loadEvents()
                    })
            }else{
                 this.axios({
                        method: 'put',
                        url: '/api/interna/updateEvent',
                        data: {
                            id: this.eventId,
                            eventname: this.eventName,
                            time: this.eventDateTime,
                            place: this.eventPlace,
                            expires: this.eventExpires,
                            others: this.eventOthers
                        },
                        headers: {'Content-Type': 'application/json;charset=UTF-8',
                                'Authorization': `token ${token}`}         
                    })
                    .then(_ => {
                        this.clearEvent()
                        this.loadEvents()
                    })
            }
        },
        validateEventInput(){
            let result = ''
            if(this.eventName === null || this.eventName.length === 0){
                result += "Es wurde keine Bezeichnung für die Veranstaltung angegeben.\n"
            }
            if(this.eventName !== null && this.eventName.length > 100){
                result += "Die Bezeichnung für die Veranstaltung ist zu lang (bis zu 100 Zeichen erlaubt).\n"
            }
            if(this.eventDateTime === null || this.eventDateTime.length === 0){
                result += "Es wurde kein Zeitpunkt für die Veranstaltung angegeben.\n"
            }
            if(this.eventDateTime !== null && this.eventDateTime.length > 50){
                result += "Die Angabe des Zeitpunktes für die Veranstaltung ist zu lang (bis zu 50 Zeichen erlaubt).\n"
            }
            if(this.eventPlace === null || this.eventPlace.length === 0){
                result += "Es wurde kein Ort für die Veranstaltung angegeben.\n"
            }
            if(this.eventPlace !== null && this.eventPlace.length > 50){
                result += "Die Angabe des Ortes für die Veranstaltung ist zu lang (bis zu 50 Zeichen erlaubt).\n"
            }
            if(this.eventOthers !== null && this.eventOthers.length > 250){
                result += "Die Angabe des Links für die Veranstaltung ist zu lang (bis zu 250 Zeichen erlaubt).\n"
            }
            if(this.eventExpires === null){
                result += "Es wurde nicht angegeben bis wann der Termin angezeigt werden soll."
            }
            return result.length === 0 ? null : result
        },
        clearEvent(){
            this.eventName = null
            this.eventDateTime = null
            this.eventPlace = null
            this.eventOthers = null
            this.eventExpires = null
            this.eventId = null
            this.eventError = null
        },
        editEvent(index){
            const selectedEvent = this.events[index]
            const dateParts = selectedEvent.expires.split('.')
            const dateToShow = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
            this.eventId = selectedEvent.id
            this.eventName = selectedEvent.eventname
            this.eventExpires = dateToShow
            this.eventDateTime = selectedEvent.date
            this.eventPlace = selectedEvent.place
            this.eventOthers = selectedEvent.others 
        }
    } 
}
</script>