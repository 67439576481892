<template>
    <div>
        <div class="downloadFolder" v-for="(doc, index) in docs" :key="doc.name">
            <img class="picFolder" src='../assets/img/Ball_gedreht.png' height='30' width='40' />
            <button class="linkButton" @click="toggleDocs(index)">{{doc.name}}</button>
            <img class="picsFolderRight" src='../assets/img/ball_gedreht_left.png' height='30' width='40'/>
                <div class="insideDiv" v-if="showDocs[index]">
                <div class="downloadDiv" v-for="file in doc.files" :key="file.filename">		 	
                <a class="downloadLink" target="_self" :href="file.path" :download="file.filename">{{file.filename}}</a>
                </div>
            </div>
        </div>
    </div>
</template> 

<script>
export default {
    name:"Material",
    data(){
        return{
            docs:[],
            showDocs:[]
        }
    },
    created(){
        this.loadDocs()
    },
    methods:{
        loadDocs(){
            const token = this.$store.getters.token
            this.axios.get('/api/interna/documents',
            {headers: 
                {
                 'Authorization': `token ${token}`
                }
            })
            .then(response => {
                this.docs = response.data.records
                for(var i = 0; i< this.docs.length; i++){
	                	this.showDocs.push(false);
	            }
            })
        },
        toggleDocs(index){
            this.showDocs[index] = !this.showDocs[index]
        }
    }
}
</script>