<template>
    <div>
        <div class="picFolder">
            <img class="picFolder" src='../assets/img/Ball_gedreht.png' height='30' width='40'/>
            <router-link class="picsButton" to="/interna/pics/Weihnachtsfeier15" >Weihnachtsfeier 2015</router-link>
            <img class="picsFolderRight" src='../assets/img/ball_gedreht_left.png' height='30' width='40'/>
        </div>
        <div class="picFolder">
            <img class="picFolder" src='../assets/img/Ball_gedreht.png' height='30' width='40'/>
            <router-link class="picsButton" to="/interna/pics/U17Mannschaft" >U17-Mannschaft 2015</router-link>
            <img class="picsFolderRight" src='../assets/img/ball_gedreht_left.png' height='30' width='40'/>
        </div>
        <div class="picFolder">
            <img class="picFolder" src='../assets/img/Ball_gedreht.png' height='30' width='40'/>
            <router-link class="picsButton" to="/interna/pics/Punktspiel140921">U15-Punktspiel 21.09.2014</router-link>
            <img class="picsFolderRight" src='../assets/img/ball_gedreht_left.png' height='30' width='40'/>
        </div>
        <div class="picFolder">
            <img class="picFolder"  src='../assets/img/Ball_gedreht.png' height='30' width='40'/>
            <router-link class="picsButton" to="/interna/pics/others">ältere Trainingsfotos</router-link>
            <img class="picsFolderRight" src='../assets/img/ball_gedreht_left.png' height='30' width='40'/>
        </div>
 </div>
</template>

<script>
export default {
    name:"Galerie"
}
</script>