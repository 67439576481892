<template>
    <div>
        <div class="username">
            <h4>Nutzernamen ändern</h4>
            <p>Das ist der Name, den du zum Einloggen benutzt und der, der intern bei der Anlage von Newseinträgen angezeigt wird.
            Für andere Nutzer des Interna-Bereichs muss dein wahrer Name also nicht erkennbar sein. Allerdings sollte dir bewusst sein,
                dass er für die Adminitratoren der Seite im Zweifel nachvollziehbar bleibt.</p>
            <form id="usernameForm" @submit.prevent="submitForm">    
                <label>neuer Nutzername: </label><input class='mobileAdjusted' type="text" v-model.trim="username" size="20">
                    <div :class="showUserMessageErr? 'warning' : 'success'" v-if="usernameMessage">{{usernameMessage}}</div>
                <div class="topspace fitContent"><button class="accountButton" @click="saveUserName()">Speichern</button></div>
            </form>
        </div>

        <div>
            <h4>Passwort ändern</h4>
            <form id="passwortForm" @submit.prevent="submitForm">
                <label class="oldPswd mobileAdjusted">altes Passwort:   </label>
                <input class='mobileAdjusted' type="password" v-model.trim="oldPswd" size="16">
                <br/>
                <br/>
                <br/>
                <label>neues Passwort:   </label>
                <input class='mobileAdjusted' type="password" v-model.trim="newPswd" size="16">
                <br/>
                <br/>
                <label>neues Passwort:   </label>
                <input class='mobileAdjusted' type="password" v-model.trim="newPswdCopy" size="16">
                <div :class="showPasswordMessageErr? 'warning' : 'success'" v-if="passwordMessage">{{passwordMessage}}</div>
                <div class="topspace fitContent"><button class="accountButton" @click="savePassword()">Passwort ändern</button></div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name:"Accounts",
    data(){
        return{
            username: null,
            oldPswd: null,
            newPswd: null,
            newPswdCopy: null,
            usernameMessage: null,
            passwordMessage: null,
            showUserMessageErr: false,
            showPasswordMessageErr: false
        }
    },
    methods:{
        saveUserName(){
            this.showUserMessageErr = false
            this.usernameMessage = null
            if(this.username !== null && this.username !== ''){
                const token = this.$store.getters.token
                this.axios({
                    method: 'put',
                    url: '/api/interna/updateUsername',
                    data: {
                        userId: this.$store.getters.userId,
                        newName: this.username,
                    },
                    headers: {'Content-Type': 'application/json;charset=UTF-8',
                            'Authorization': `token ${token}`}         
                })
                .then(_=>{
                    this.usernameMessage = "Das hat geklappt. Dein Nutzername lautet nun: "+this.username
                    this.$store.dispatch('updateUsername',{username : this.username})
                })
                .catch(_=>{
                    this.usernameMessage = "Hier ist leider ein Fehler aufgetreten, der Name konnte nicht geändert werden."
                    this.showUserMessageErr = true
                })
            }else{
                this.usernameMessage = "Bitte gib zunächst einen neuen Namen ein."
                this.showUserMessageErr = true
            }
        },
        savePassword(){
            this.showPasswordMessageErr = false
            this.passwordMessage = null
            if(this.newPswd === null || this.newPswd === '' || this.newPswdCopy === null || this.newPswdCopy === ''){
                this.showPasswordMessageErr = true
                this.passwordMessage = "Bitte gib das neue Passwort und eine Wiederholung davon ein."
                return
            }
            if(this.newPswd.length < 6){
                this.showPasswordMessageErr = true
                this.passwordMessage = "Das Passwort muss mind. 6 Zeichen lang sein."
                return
            }
            if(!(/\d/.test(this.newPswd)) || !(/[A-Z]/.test(this.newPswd))){
                this.showPasswordMessageErr = true
                this.passwordMessage = "Das Passwort muss mind.  eine Zahl und einen Großbuchstaben enthalten."
                return
            }if(this.newPswd !== this.newPswdCopy){
                this.showPasswordMessageErr = true
                this.passwordMessage = "Die Wiederholung stimmt nicht mit dem Passwort überein."
                return
            }
            const token = this.$store.getters.token
            this.axios({
                    method: 'put',
                    url: '/api/interna/changePassword',
                    data: {
                        userId: this.$store.getters.userId,
                        oldPassword: this.oldPswd,
                        newPassword: this.newPswd
                    },
                    headers: {'Content-Type': 'application/json;charset=UTF-8',
                            'Authorization': `token ${token}`}         
                })
                .then(_=>{
                    this.passwordMessage = "Das hat geklappt. Dein Passwort wurde geändert."
                })
                .catch(_=>{
                    this.passwordMessage = "Hier ist leider ein Fehler aufgetreten, das Passwort konnte nicht geändert werden."
                    this.showPasswordMessageErr = true
                })
        }
    }

}
</script>