<template>
    <div>
        <h3>Verbände</h3>
        <p><a class='links' href='http://www.kbvkiel.de/' target='_blank'>Kreisfachverband Badminton Kiel </a></p>
        <p><a class='links' href='http://www.shbv.de/' target='_blank'>Schleswig-Holsteinischer Badminton Verband </a></p>
        <div class='topspace'>
            <h3> Verein </h3>
            <p><a class='links' href='https://www.tus-holtenau.de/Sport/Ballsport/Badminton' target='_blank'>
                 Vereinshomepage
               </a>
            </p>
            <p class='shorttext'>Hier findet ihr das <a class="downloadLink" target="_blank" href="/download/Aufnahmeantrag.pdf" download="Aufnahmeantrag">Aufnahmeformular</a> und die <a class="downloadLink" target="_blank" href="/download/Erlaubnis_am_Bild.pdf" download="ErlaubnisamBild">Erklärung</a>
                zur Verwendung von Fotos der Kinder auf der Homepage.</p>
        </div>
        <div class='topspace'>
            <h3> Ergebnisdienst </h3>
            <p><a class='links' href='https://www.turnier.de/sport/league?id=CEA93BF4-89F7-4DC5-A20E-6B84FA2E7287' target='_blank'>
                Spielpläne und Ergebnisse auf turnier.de
            </a>
            </p>
        </div>
        <div class='topspace'>
            <h3> Aktuelles im Badmintonsport </h3>
            <p><a class='links' href='http://www.badminton.de/' target='_blank'>
                Deutscher Badmintonverband
            </a>
            </p>
            <p><a class='links' href='http://www.bwfbadminton.com/' target='_blank'>
                Internationaler Badmintonverband (BWF)
            </a>
            </p>
            <p class='link'>
            Außerdem gibt es eine <b>App des BWF</b>, wo sich insbesondere internationale Turniere gut verfolgen lassen:<br/>
            <a class='links' href='https://bwfbadminton.com/badminton4u/' target='_blank'><b>Badminton4U</b></a>
            </p>
            <p class='link'><a class='links' href='https://www.youtube.com/user/bwf' target='_blank'>
                <b>Videos</b> aktueller internationaler Spiele (BWF.TV)
            </a>
            </p>
            <p class='link'><a class='links' href='https://www.tobiaswadenka.com/podcast/' target='_blank'>
            Shuttletalk-<b>Podcast</b>
            </a>
            <br/>Interessante und witzige News aus dem deutschen und internationalen Badmintonsport für Spieler, Trainer und andere Badmintonverrückte.<br/>
            Natürlich auch überall, wo ihr sonst Podcasts hört.
            </p>
             <p class='link'><a class='links' href='https://www.youtube.com/user/bwf' target='_blank'>
                BadmintONEarth
            </a>
            <br/> Klimainitiative im Rahmen von Sports4Trees
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name:"Links"
}
</script>