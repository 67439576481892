<template>
    <div>
    <p>Dieser Bereich ist unseren Trainern vorbehalten.</p>

        <form name="loginForm" ref="loginForm" @submit.prevent="submitForm">
            <div class="login">
            <label user>Nutzer:</label><br/>
            <input class='mobileAdjusted' type="text" name="user" v-model.trim="userName" size="20" required>
            <br/>
            <br/>
            <label password> Passwort:</label><br/>
            <input class='mobileAdjusted' type="password" name="pswd" v-model.trim="pswd" size="20" required>
            </div>
            <router-link class="newsButton" to="/home">Abbrechen</router-link>
            <button class="newsButton" @click="login()" :disabled=" isFormNotFilled() || loading">Login</button>
        </form>
        <div class="loginMsg" v-if="loginMessage">
            {{loginMessage}}
        </div>
    </div>   
</template>

<script>
export default {
    name:"Login",
    data(){
        return{
            userName: null,
            pswd: null,
            loginMessage:null,
            loading: false
        }
    },
    methods:{
        isFormNotFilled(){
            return this.userName === null || this.pswd === null || this.userName === '' || this.pswd === ''
        },
        async login(){
            this.loading = true
            this.loginMessage = null

            try{
                await this.$store.dispatch('login', {
                        userName : this.userName,
                        pswd : this.pswd     
                    })
                this.$router.replace("/interna/home")    
            }
           catch(error){
                this.loading = false               
                this.userName = null
                this.pswd = null
                if(error.response.status === 404){
                    this.loginMessage = "Nutzername oder Passwort falsch. Bitte versuche es noch einmal."
                }else{
                     this.loginMessage = "Es ist ein technischer Fehler beim Login aufgetreten. Bitte melde das Problem dem Admin."
                }
            }
        }
    }
}
</script>