<template>
    <div>
        <events-table title="Allgemeine Termine" :events="events"/>
        <!-- ggf kann man hier noch über Altersklasse iterieren-->
        <matches-table v-if="matchesU13.length > 0" :matches="matchesU13" title="Punktspieltermine U13"/>
        <matches-table v-if="matchesU15.length > 0" :matches="matchesU15" title="Punktspieltermine U15"/>
        <matches-table v-if="matchesU19.length > 0" :matches="matchesU19" title="Punktspieltermine U17/U19"/>   
    </div>    
</template>

<script>
import MatchesTable from './base/MatchesTable.vue'
import EventsTable from './base/EventsTable.vue'

export default {
    name:"Termine",
    components:{
        MatchesTable,
        EventsTable
    },
    data(){
        return{
            events: [],
            matchesU13: [],
            matchesU15: [],
            matchesU19: []
        }
    },
    created(){
        this.loadEvents()
        this.loadMatches()
    },
    methods:{
        loadEvents(){
            this.axios.get('/api/events')
                .then(response => {
                    this.events = response.data.records
                }) 
        },
        loadMatches(){
            this.axios.get('/api/matches/1')
                .then(response => {
                    this.matchesU13 = response.data.records
                })
            this.axios.get('/api/matches/2')
                .then(response => {
                    this.matchesU15 = response.data.records
                })
            this.axios.get('/api/matches/3')
                .then(response => {
                    this.matchesU19 = response.data.records
                })
        }
    }
}
</script>