import { createRouter, createWebHistory } from 'vue-router';
import store from './store.js'

import Home from './components/Home.vue';
// import Berichte from './components/Berichte.vue';
// import Article from './components/Article.vue';
import Training from './components/Training.vue';
import Teams from './components/Teams.vue';
import Termine from './components/Termine.vue';
import Links from './components/Links.vue';
import Impressum from './components/Impressum.vue';
import MailForm from './components/MailForm.vue';
import Login from './components/Login.vue';
import Interna from './components/Interna.vue';
import InternaHome from './components/InternaHome.vue';
import Galerie from './components/Galerie.vue';
import PictureView from './components/PictureView.vue';
import Material from './components/Material.vue';
import Accounts from './components/Accounts.vue';
import NewsEditor from './components/NewsEditor.vue';
import TerminEditor from './components/TerminEditor.vue';


const router = createRouter({
    history: createWebHistory(),
    routes:[
        {path: '/', redirect: '/home'},
        {path: '/home', component: Home},
        // {path: '/reports', component: Berichte},
        // {path: '/article/:articleId', component: Article, props:true},
        {path: '/training', component: Training},
        {path: '/teams', component: Teams},
        {path: '/events', component: Termine},
        {path: '/links', component: Links},
        {path: '/impressum', component: Impressum},
        {path: '/contact', component: MailForm},
        {path: '/login', component: Login, meta: { requiresUnauth: true }},
        {path: '/interna', component: Interna, meta: { requiresAuth: true },
            children:[
                {path: 'home', component: InternaHome, meta: { requiresAuth: true }},
                {path: 'galerie', component: Galerie, meta: { requiresAuth: true }},
                {path: 'pics/:folderName', component: PictureView, props:true, meta: { requiresAuth: true }},
                {path: 'material', component: Material, meta: { requiresAuth: true }},
                {path: 'newseditor', component: NewsEditor, meta: { requiresAuth: true }},
                {path: 'accounts', component: Accounts, meta: { requiresAuth: true }},
                {path: 'termineditor', component: TerminEditor, meta: { requiresAuth: true }}
            ]}
    ]
});


router.beforeEach(function(to, _, next) {
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
      next('/login');
    } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
      next('/interna/home');
    } else {
      next();
    }
  });

export default router;
