<template>
    <div>
        <div class="editor">
            <h4>Hier kannst du einen neuen Eintrag erstellen:</h4>
            <form id="newsForm" @submit.prevent="submitForm">
                <label>Überschrift: </label>
                <br/>
                <input class='mobileAdjusted' type="text" v-model.trim="title" size="100"/>
                <br/>
                <span style="color:red" v-if="showErrorTitle">
                    <span>Bitte einen Titel eingeben.</span>
                </span>
                <br/>
                <label>Text:</label>
                <br/>
                <br/>
                <textarea class='mobileAdjusted' rows="10" cols="100" v-model.trim="text"/>
                <br/>
                <span style="color:red" v-if="showErrorText">
                    <span>Bitte einen Text eingeben.</span>
                </span>
            </form>    
            <div>
                <p>So wird dein Eintrag auf der Startseite aussehen:</p>
                <div class="shortNote"> 
                    <p class='smalldate'>{{today}}</p>
                    <h4 class="shortNote">{{title}}</h4>
                    <p class='shorttext'>{{preparedtext}} </p>
                </div> 
            </div>
            <div class="topspace fitContent">
                <button class="accountButton" @click="saveEntry()">Speichern</button>
            </div>
        </div>    
        <div> 
            <h4>Hier kannst du bereits gespeicherte Einträge bearbeiten:</h4>    
            <div class="editor" v-for="(newsEntry, index) in news" :key="newsEntry.id"> 
                <p class='smalldate'>{{newsEntry.date}}</p>
                <h4 class="shortNote">{{newsEntry.title}}</h4>
                <textarea class='mobileAdjusted' rows="10" cols="100" v-model.trim="newsEntry.text" v-if="entryEditable[index]"/>
                <p class='shorttext' v-else>{{newsEntry.text}} </p>
                <div class="topspace fitContent">
                    <button class="accountButton" v-if="!entryEditable[index]" @click="entryEditable[index] = true">Bearbeiten</button>
                    <button class="accountButton" v-else @click="editEntry(index, newsEntry.id)">Speichern</button>
                    <button class="accountButton" @click="entryEditable[index] = false">Abbrechen</button>
                    <button class="accountButton" @click="deleteEntry(newsEntry.id)">Eintrag löschen</button>
                </div>
            </div>    
        </div>   
    </div>
</template>

<script>
export default {
    name:"NewsEditor",
    data(){
        return{
            title: null,
            text: null,
            showErrorTitle: false,
            showErrorText: false,
            news: [],
            entryEditable: []
        }
    },
    created(){
        this.loadNews()
    },
    computed:{
        today(){
            const today = new Date()
            const dd = today.getDate()
            const mm = today.getMonth()+1
            const yyyy = today.getFullYear()
            return `${dd}.${mm}.${yyyy}`
        },
        preparedtext(){
            return this.text? this.text.replace(/\n/g, "\n\r") : this.text;
        }
    },
    methods:{
        loadNews(){
            this.axios.get('api/newsEntries')
            .then(result =>{
                this.news = result.data.records
                this.entryEditable = []
                for(var i = 0; i< this.news.length; i++){
	                	this.entryEditable.push(false);
	            }
            })
        },
        saveEntry(){
            if(this.title === null || this.title === ''){
                this.showErrorTitle = true
                return
            }
            if(this.text === null || this.text === ''){
                this.showErrorText = true
                return
            }

            const token = this.$store.getters.token
            this.axios({
                    method: 'post',
                    url: '/api/interna/newsEntry',
                    data: {
                        title: this.title,
                        text: this.text.replace(/\n/g, "\\n"),
                        date: this.today
                    },
                    headers: {'Content-Type': 'application/json;charset=UTF-8',
                            'Authorization': `token ${token}`}         
                })
                .then(_ => {
                    this.title = null
                    this.text = null
                    this.showErrorText = false
                    this.showErrorTitle = false
                    this.loadNews()
                })
        },
        editEntry(index, id){
            const token = this.$store.getters.token
            this.axios({
                method: 'put',
                url: '/api/interna/updateEntry',
                data: {
                    id: id,
                    text: this.news[index].text.replace(/\n/g, "\\n"),
                },
                headers: {'Content-Type': 'application/json;charset=UTF-8',
                        'Authorization': `token ${token}`}         
            })
            .then(_ => {
                this.loadNews()
            })
        },
        deleteEntry(id){
            const token = this.$store.getters.token
            this.axios({
                    method: 'post',
                    url: '/api/interna/deleteEntry',
                    data: {
                        id: id
                    },
                    headers: {'Content-Type': 'application/json;charset=UTF-8',
                            'Authorization': `token ${token}`}         
                })
                .then(_ => {
                    this.loadNews()
                })
        }
    }
}
</script>