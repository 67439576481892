<template>
    <div>
        <div v-if="!sendAttempt">
            <p> Über dieses Formular könnt ihr uns Nachrichten senden.
                Bitte vergesst nicht eure email-Adresse anzugeben, damit wir euch antworten können.
                Falls ihr am Training teilnehmen wollt, meldet euch vorzugsweise über 0170-5356339.</p>

            <form name="emailForm" ref="emailForm" @submit.prevent="submitForm">
                <div id='texts'>
                    <label subject >	Betreff:</label><br/>
                    <input class='mobileAdjusted' type='text' v-model.trim="subject" size='60' required>
                    <br/><br/>
                    <label from >	Antwortadresse:</label><br/>
                    <input class='mobileAdjusted' type='email' name="email" v-model.trim="sender" size='60' required>
                    <br/>
                    <span style="color:red" v-if="checkEmailValid()">
                        <span v-if="sender === null">Bitte email-Adresse eingeben.</span>
                        <span v-else-if="checkEmailValid()">Adresse ungültig!.</span>
                    </span>
                    <br/><br/>
                    <label message> Nachricht:</label> <br/>
                    <textarea class='mobileAdjusted' v-model.trim="message" name="message" rows='15' cols='60' required></textarea><br/>
                </div>
                <div>
                    <button class='newsButton' @click="abort()"> Abbrechen </button>
                    <button  class='newsButton' @click=sendMailAndClear() :disabled="isFormInvalid()"> Absenden </button>
                </div>
            </form>
        </div>

        <div v-else>
            <span :class="!success ? alert : none">
                {{mailSuccessMsg}}
            </span>
            <br/>
            <button class='newsButton' @click="showForm()">Zurück </button>
        </div>
    </div>    
</template>

<script>
export default {
    name:"MailForm",
    data(){
        return{
            sendAttempt : false,
            success: true,
            mailSuccessMsg :null,
            subject: null,
            sender: null,
            message :null
        }
    },
    methods:{
        checkEmailValid(){
            // this.sender !== null  && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.sender))
            return this.sender !== null  && !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.sender))
        },
        isFormInvalid(){
            this.checkEmailValid || this.message === null || this.message === ''
        },
        sendMailAndClear(){
            this.axios({
                method: 'post',
                url: '/api/mail',
                data: {
                    subject: this.subject,
                    sender: this.sender,
                    message: this.message.replace(/\r?\n/g, "\\n")
                },
                headers: {'Content-Type': 'application/json;charset=UTF-8'}           
                })
                .then(response => {
                    this.sendAttempt = true
                    this.success = true
                    this.mailSuccessMsg = response.data.message
                    this.clear()
                }).catch(error => {
                    this.sendAttempt = true
                    this.success = false
                    this.mailSuccessMsg = error.data
                })
        },
        showForm(){
            this.sendAttempt = false
        },
        abort(){
            this.clear()
            this.$router.push("/home")
        },
        clear(){
            this.sender = ""
            this.message = ""
            this.subject = ""
        }
    }
}
</script>