<template>
    <div id="main">
        <main-menu :open-menu="menuCheckbox" @hideMenu="hideMenu()"></main-menu>
        <div id="content-side">
            <div id="header">       
                <label for="responsive-nav" class="responsive-nav-label"><img src='./assets/img/navicon.png' alt='menu' height='40' width='40'></label>
                <input type="checkbox" id="responsive-nav" v-model="menuCheckbox" @click="showMenu()"/>
                <router-link class="linkButton" id="headline" to="/"><h1>TuS Holtenau Badminton</h1></router-link>
            </div>
            <router-view>               
            </router-view>
        </div>
    </div>  
</template>

<script>
import MainMenu from './components/MainMenu.vue'
export default {
    name: 'main-app',
    components: {MainMenu}, 
    data(){
        return{
            //nur für mobile-Ansicht, wird über css geregelt
            menuCheckbox: false
        }
    },
    created() {
        this.$store.dispatch('tryLogin')
    },
    methods:{
        showMenu() {
            this.menuCheckbox = true
        },
        hideMenu(){
            this.menuCheckbox = false
        }
    }
}
</script>