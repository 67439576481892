import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store.js'
import router from './router.js'
import MainApp from './MainApp.vue'
import isMobile from './viewport.js'

const mobileCss = () => import('@/css/design_mobile.css')
const desktopCss = () => import('@/css/design_alt.css')

if(isMobile()){
    mobileCss()
}else{
    desktopCss()
}

const app = createApp(MainApp);

app.use(router);
app.use(store);

app.use(VueAxios, axios)

app.mount('#app'); 
 