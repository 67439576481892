<template>
    <div>
        <h3>{{title}}</h3>
        <table class="eventstab mobile">
        <thead class='mobile'>
            <tr class='mobile'>
                <th class="termine mobile">Gegner</th>
                <th class="termine mobile">Datum</th>
                <th class="termine mobile">Uhrzeit</th>
                <th :class="[editable? 'termine' : 'terminelast' , 'mobile']">Ort</th>
                <th class="terminelast mobile" v-if="editable"></th>
            </tr>
        </thead>  
        <tbody class='mobile'>
            <tr :class="[editable? 'modileeditable' : 'mobile']" v-for="(x, index) in matches" :key="x.key">
                <td class="termine mobile punktspiel"> {{x.opponent}} </td>
                <td class="termine mobile punktspiel"> {{x.date}} </td>
                <td class="termine mobile punktspiel"> {{x.time}}</td>
                <td :class="[editable? 'termine' : 'terminelast' , 'mobile' ,'punktspiel']"> {{x.place}} </td>
                <td class="terminelast mobile punktspiel" v-if="editable"><button class="accountButton" @click="editMatch(index)">Bearbeiten</button></td>
            </tr>
        </tbody>  
        </table>
    </div>    
</template>

<script>
export default {
    name:"matchesTable",
    props:{
        title:{
            type:String
        },
        matches:{
            type:Array,
            default: () => []
        },
        editable:{
            type: Boolean,
            default: false
        }
    },
    methods:{
        editMatch(index){
            this.$emit('editmatch', index)
        }
    }
}
</script>
