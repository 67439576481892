<template>
<div>
    <div v-if="onlyCurrentSeason">
        <h3>Saison 2022/23</h3>
        <p>Leider muss die Punktspielrunde diese Saison ohne uns stattfinden. <br/> Verstärke unsere Teams (aller Altersklassen), damit wir nächste Saison dann gemeinsam um die Meisterschaft spielen können!</p>
        <button class="newsButton" @click="showOlderTeams(true)">Vergangene Jahre</button>
    </div>

    <div v-else>
        <button class="newsButton" @click="showOlderTeams(false)">Zurück</button>
        <h3>Saison 2019/20</h3>
        <p>Nach den Sommerferien starten unser neu eingekleidetes U17-Team in die neue Saison.</p>
        <p> <a class='table' href='https://www.turnier.de/sport/event.aspx?id=8D705F53-3CA4-4C14-AF18-9E76EE700CC6&event=9' target='blank'>Hier</a> werdet ihr künftig die Ergebnisse finden.</p>    
        <div><p><img  class='mobileAdjusted' src='../assets/img/Mannschaft1920_1.jpg' alt='U17' height='350' width='380'/></p>
            v.l.n.r.: Tjure, Julia, Luca (Trainer), Domenique und Lasse</div>
            <br/>
        <h3>Saison 2018/19</h3>
        <p>Unser gut besetztes U15-Team holt nach einer starken Saison Bronze!</p>
        <p> <a class='table' href='https://www.turnier.de/sport/draw.aspx?id=D8AD2F1A-64F0-4121-84D7-8FCD36BDBDCB&draw=21' target='blank'>Hier</a> findet ihr die aktuelle Tabelle.</p>    
        <div><p><img  class='mobileAdjusted' src='../assets/img/Mannschaft_1819.jpg' alt='U15' height='390' width='330'/></p>
            v.l.n.r.: Lasse, Sara, Domenique und Julia</div>
            <br/>
        <h3>Saison 2017/18</h3>
        <p>Unsere U11 und U13-Spieler/innen nehmen in dieser Saison zum ersten Mal an der Punktspielrunde teil</p>
        <p> <a class='table' href='https://www.turnier.de/sport/draw.aspx?id=188240D1-C9EB-4F0A-888B-8EAE84765A23&draw=41' target='blank'>Hier</a> findet ihr die Tabelle der Hinrunde.</p>
        <p>Am Rückrunden-Blockspieltag konnte die Mannschaft ein Unentschieden gegen den Preetzer TSV erspielen, musste sich dann jedoch gegen den SC Itzehoe mit 2:4 geschlagen geben.</p>
        <p> Somit erreichten unsere Spieler und Spielerinnen in ihrer ersten Saison einen hervorragenden 2.Platz in der 2. Kreisklasse.</p>
        <div><p><img  class='mobileAdjusted' src='../assets/img/MannschaftU13.jpg' alt='U13' height='300' width='390'/></p>
            v.l.n.r.: Julia(Trainerin), Domenique, Jannik, Sara, Tjure und Julia</div>
            <br/>
        <h3>Saison 2016/17</h3>
        <h4>Unsere U17/U19-Mannschaft wird zum zweiten Mal Meister in der 2. Kreisklasse!</h4>
        <p> <a class='table' href='http://www.turnier.de/sport/draw.aspx?id=91B40F17-77CB-43E4-A016-0B9033DAF488&draw=37' target='blank'>Hier</a> findet ihr die Tabelle.</p>
        <div><p><img class='mobileAdjusted' src='../assets/img/MannschaftU17U19_gross.jpg' alt='U17U19' height='300' width='390'/></p>
            v.l.n.r.: Oscar, Korben, Theo, Luca, Linn, Emre und Daniel</div>
        <h3>Saison 2015/16</h3>
        <h4>U17 wird Vize-Meister in der 2.Kreisklasse U17/U19!</h4>
        <p> Hier findet ihr die Ergebnistabellen von Vor- und Rückrunde:
        <br/>
            <a class='table' href='http://www.turnier.de/sport/draw.aspx?id=6F54264C-24F1-46D6-8B4F-1E946D61B2EE&draw=29' target='_blank'>U17/U19, Staffel A (Hinrunde)</a>
            <br/>
            <a class='table' href='http://www.turnier.de/sport/draw.aspx?id=6F54264C-24F1-46D6-8B4F-1E946D61B2EE&draw=32' target='_blank'>U17/U19, 2.KK (Rückrunde)</a>
        </p>
        <h4>U15 holt den dritten Platz in der Gesamtstaffel</h4>
        <p>Ergebnistabellen gibts hier:
        <br/>
            <a class='table' href='http://www.turnier.de/sport/draw.aspx?id=6F54264C-24F1-46D6-8B4F-1E946D61B2EE&draw=33' target='_blank'>U15</a>
        </p>    
        <h3>Saison 2014/15</h3>
        <h4>U15 - 2. Kreisklasse </h4>
        <p> <b> WIR SIND KREISMEISTER!!! </b> </p>
        <div><p><img class='mobileAdjusted' src='../assets/img/Mannschaft.jpg' alt='U15' height='250' width='330'/></p>
            v.l.n.r.: Julia (Trainerin), Korben, Linn, Elia, Michel, Jannes, Theo, Luca, (es fehlt Emre)</div>
        <p><a class='table' href='http://turnier.de/sport/draw.aspx?id=A7336E5C-2A69-4118-885B-BE99A1FD40E8&draw=23' target='_blank'>Tabelle</a></p>
        <h3>Saison 2013/14</h3>
        <h4> U15 - 1. Kreisklasse </h4>
        <div><div><p><img class='mobileAdjusted' src='../assets/img/U15.jpg' alt='U15' height='190' width='280'/></p>
            Luca, Korben und Theo (es fehlt Emre)</div>
            <p><a class='table' href='http://www.turnier.de/sport/draw.aspx?id=0F72A5B5-C354-4F30-A9A9-686947486400&draw=39' target='_blank'>Tabelle Hinrunde</a></p>
            <p><a class='table' href='http://www.turnier.de/sport/draw.aspx?id=0F72A5B5-C354-4F30-A9A9-686947486400&draw=46' target='_blank'>Tabelle Rückrunde</a></p></div>
        <h4> U13 </h4>
        <div><div><p><img class='mobileAdjusted' src='../assets/img/U13.jpg' alt='U13' height='190' width='280'/></p> Elia, Jannes, Michel und Linn</div>
            <p><a class='table' href='http://www.turnier.de/sport/draw.aspx?id=0F72A5B5-C354-4F30-A9A9-686947486400&draw=21' target='_blank'>Tabelle</a></p></div>
        <button class="newsButton" @click="showOlderTeams(false)">Zurück</button>
    </div>
</div>    
</template>

<script>
export default {
    name:"Teams",
    data(){
        return{
            onlyCurrentSeason: true
        }
    },
    methods:{
        showOlderTeams(showAll){
            this.onlyCurrentSeason = !showAll
        }
    }
}
</script>
