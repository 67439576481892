<template>
     <tabs-view :tabs="['Punktspiele', 'Allgemeine Termine']" @selectTab="setTabActive">
        <div v-show="activeTab === 0" class="tab"> 
            <matches-tab/>
        </div>
        <div v-show="activeTab === 1" class="tab">
            <events-tab/>
        </div>        
    </tabs-view>
</template>

<script>

import TabsView from './base/TabsView.vue'
import MatchesTab from './MatchesTab.vue'
import EventsTab from './EventsTab.vue'

export default {
    name:"TerminEditor",
    components:{
        MatchesTab, EventsTab, TabsView
    },
    data(){
        return{
            activeTab: 0
        }
    },
    created(){
        this.activeTab = 0
    },
    methods:{
        setTabActive(index){
            this.activeTab = index
        }
    }
}
</script>
