<template>
    <div>
        <p>Herzlich Willkommen im Interna-Bereich, {{username}}.</p>
        <p>Dieser Teil der Homepage ist für den Austausch der Trainer gedacht.</p>
        <div class="int-main">
            <img class="galleryBigPic" src="../assets/img/GruppeGemischt.jpg" height="220" width="340" alt="Gruppenfoto"/>
        </div>
        <p>In der <router-link class="mailButton" to="/interna/galerie">Galerie</router-link> , gibt es ältere Fotos, die mal beim Training,
            auf Punktspielen oder der Weihnachtsfeier entstanden sind.
        </p>
        <p>Unter <router-link class="mailButton" to="/interna/material">Material</router-link> gibt es für (zukünftige) Trainer Dokumente zur Ausbildung, Trainingsplanung und Organisation zum Download.</p>
        <p><router-link class="mailButton" to="/interna/accounts">Hier</router-link> könnt ihr eure Nutzerdaten ändern.
        </p>
        <p>Außerdem könnt ihr <router-link class="mailButton" to="/interna/newseditor">hier</router-link> neue Meldungen für den Newsfeed auf der Startseite verfassen und 
        <router-link class="mailButton" to="/interna/termineditor">hier</router-link> Termine verwalten.
        </p>
    </div>
</template>

<script>
export default {
    name:"InternaHome",
    computed:{
        username(){
            return this.$store.getters.username;
        }
    }
}
</script>