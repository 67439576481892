<template>
    <div>
        <h3>Willkommen <br/>
        auf der Homepage der Badmintonjugend des TuS Holtenau.</h3>
        <div class="showContainer">

        <img class='bottomspace mobileAdjusted' id='show' align='center' src='../assets/img/Mannschaft1920_2.jpg' alt='Badmintonjugend' height='310' width='420'/>
            <div class ='sideBySide'><img class="hideForMobile" src='../assets/img/Sara20171007.jpg' alt='Sara' height='140' width='90'/>   						
                                <img class="hideForMobile" src='../assets/img/Medaillen.jpg' alt='Medaillen' height='140' width='220'/>
                                <img class="hideForMobile" src='../assets/img/MannschaftU13.jpg' alt='Gruppe' height='140' width='180'/>
                                <img class="hideForMobile" src='../assets/img/JannikTjure20171007Gespiegelt.jpg' alt='JannikTjure' height='140' width='100'/></div>
        </div>
        <div class='bigBottomspace'><p> Viel Spaß beim Stöbern auf unserer Seite.</p>
        </div>
        <div> 
            <h3>Aktuelles</h3>    
            <div class="shortNote" v-for="newsEntry in news" :key="newsEntry.id"> 
                <p class='smalldate'>{{newsEntry.date}}</p>
                <h4 class="shortNote">{{newsEntry.title}}</h4>
                <p class='shorttext'>{{newsEntry.text}} </p>
            </div>    
        </div>    
    </div>
</template>

<script>
export default {
    name: "Home",
    data(){
        return{
            news: []
        }
    },
    created(){
        this.loadNews()
    },
    methods:{
        loadNews(){
            this.axios.get('api/newsEntries')
            .then(result =>{
                this.news = result.data.records
            })
        }
    }
}
</script>